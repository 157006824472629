import instance from './request.config';
import axios, { AxiosRequestConfig } from 'axios';

export enum Method {
  POST = 'POST',
  GET = 'GET',
}

function request<Req> (config: AxiosRequestConfig) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const customRequest: {
    cancel?: () => void
  } & Promise<Req> = instance.request({
    ...config,
    cancelToken: source.token
  });

  customRequest.cancel = () => {
    source.cancel();
  };

  return customRequest;
}

export default request;
