import { StyledHeader } from './style';
import { ReactComponent as HeaderIcon } from '$assets/svg/header-icon.svg';
import { ReactComponent as PageIcon } from '$assets/svg/page.svg';
import { ReactComponent as WishIcon } from '$assets/svg/wish-icon.svg';
import { ReactComponent as PersonalInfo } from '$assets/svg/personal-info.svg';
import { ReactComponent as Setting } from '$assets/svg/setting.svg';
import { useNavigate } from 'react-router-dom';
import { useCallback, useContext, useMemo } from 'react';
import { Button, Popover } from 'antd';
import { paths } from '$const/paths';
import QRCodeLoginModalContext from '$context/QRCodeLoginModalContext';
import { UserInfoContext } from '$context/UserInfoContext';

const popoverList = [
  {
    icon: <PageIcon/>,
    name: '我的订单',
    path: paths.order
  },
  {
    icon: <WishIcon/>,
    name: '我的志愿',
    path: `${paths.wish}?defaultTab=1`
  },
  {
    icon: <PersonalInfo style={{ width: 44 }}/>,
    name: '个人资料',
    path: `${paths.wish}?defaultTab=3`
  },
  {
    icon: <Setting/>,
    name: '账户设置',
    path: `${paths.my}?defaultTab=setting`
  }
];

const Header = () => {
  const navigate = useNavigate();

  const { openQrCodeLoginModal } = useContext(QRCodeLoginModalContext);

  const login = useCallback(() => {
    openQrCodeLoginModal();
  }, [openQrCodeLoginModal]);

  const register = useCallback(() => {
    openQrCodeLoginModal();
  }, [openQrCodeLoginModal]);

  const handleHeaderIconClick = useCallback(() => {
    navigate(paths.wish);
  }, [navigate]);

  const { userInfo, logout } = useContext(UserInfoContext);

  const isLogin = useMemo(() => {
    return Boolean(userInfo?.id);
  }, [userInfo]);

  const handleLogoutClick = useCallback(() => {
    void logout();
  }, [logout]);

  const popOverContent = useMemo(() => {
    return <div>
      {
        popoverList.map((item, index) => {
          const { icon, name, path } = item;
          return <div
            className='item'
            key={index}
            onClick={() => (path != null) && navigate(path)}
          >
            <div className='icon-wrap'>
              {icon}
            </div>
            <div className='name'>{name}</div>
          </div>;
        })
      }
    </div>;
  }, [navigate]);

  return <StyledHeader>
    <HeaderIcon className='icon'/>
    <div id="popover-mount-point"/>
    {/* <HeaderIcon onClick={handleHeaderIconClick} className='icon'/> */}
    {/* <div className='right-btns'>
      {
        rightBtns.map((item, index) => {
          const { label, redirectPath } = item;
          return <Button
            type='text'
            className='btn'
            key={index}
            onClick={() => navigate(redirectPath)}
          >{label}</Button>;
        })
      }
    </div> */}
    <div className='separator'></div>
    {
      isLogin
        ? <div className='login-rigster'>
          <Button className='btn' type='text' onClick={() => navigate(`${paths.wish}?defaultTab=1`)}>我的</Button>
        <Popover
          placement="bottom"
          content={popOverContent}
          getPopupContainer={() => document.getElementById('popover-mount-point') as HTMLElement}
          zIndex={99}
        >
        </Popover>
        <div>/</div>
        <Button className='btn' type='text' onClick={handleLogoutClick}>退出</Button>
      </div>
        : <div className='login-rigster'>
        <Button className='btn' type='text' onClick={login}>登录</Button>
        <div>/</div>
        <Button className='btn' type='text' onClick={register}>注册</Button>
      </div>
    }
  </StyledHeader>;
};

export default Header;
