import { noop } from 'lodash';
import { createContext } from 'react';
import { QRCodeLoginModalContextType } from '$components/qr-code-login-modal/interface';

const QRCodeLoginModalContext = createContext<QRCodeLoginModalContextType>({
  modalOpen: false,
  openQrCodeLoginModal: noop
});

export default QRCodeLoginModalContext;
