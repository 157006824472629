import styled from '$utils/styled';
import { Modal } from 'antd';

export const StyledQRCodeLoginModal = styled(Modal)`
  .ant-modal-content{
    border-radius: 10px;
  }
  .ant-modal-header{
    border-radius: 10px;
    border-bottom: 0px;
    display: flex;
    justify-content: center;
    .ant-modal-title{
      padding-top: 20px;
      width: fit-content;
      position: relative;
      font-size: 20px;
      ::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: " ";
        width: 100%;
        height: 15px;
        opacity: 0.25;
        background-color: #081B30
      }
    }
  }
  .ant-modal-body{
    padding-top: 10px;
    .modal-content{
      color: #9B9B9B;
      display: flex;
      flex-direction: column;
      align-items: center;
      .qr-code{
        margin-top: 20px;
        width: 139px;
        height: 139px;
        margin-bottom: 25px
      }
      .line{
        font-size: 14px;
        color: '#9b9b9b';
        display: flex;
        align-items: center;
        line-height: 30px;
        .in-line-btn{
          font-size: 12px;
          padding: 0px;
        }
        >*{
          margin-left: 5px;
          padding: 0px;
        }
      }
    }
  }
`;
