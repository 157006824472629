export const formatObjectToFormData = (param?: Object, form?: boolean) => {
  if (form) {
    const formData = new FormData();
    Object.keys(param ?? {}).forEach((key) => {
      // @ts-expect-error
      const value = param[key];
      formData.append(key, value);
    });
    return formData;
  }
  return param;
};

export function getFromCookie (sKey: string) {
  return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null;
}

export function deleteAllCookies () {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}
