const apiBase = '/api';
const apiV2Base = '/apiv2';

export const homeUrl = {
  homeData: `${apiBase}/home/homedata.json`
};

export const schoolUrl = {
  listSchool: `${apiBase}/college/listcolleges.json`,
  listCountry: `${apiBase}/country/listcountries.json`
};

export const academyUrl = {
  listAcademy: `${apiBase}/dept/getcollegedepts.json`,
  listDepartmentsByCollegeId: `${apiV2Base}/department/listByCollegeId`,
  getCollegeDetailById: `${apiV2Base}/college/getDetailById`
};

export const departmentUrl = {
  listWithProjectsByCollegeId: `${apiV2Base}/department/listWithProjectsByCollegeId`
};

export const projectsUrl = {
  getAllCountryAndGroup: `${apiBase}/country/getallcountryandgroup.json`,
  listProjects: `${apiBase}/project/listallprojects.json`,
  getProjectDetail: `${apiBase}/project/projectdetail.json`,
  addFavoriteProject: `${apiBase}/project/adduserfavoriteproject.json`,
  getProjectBuyDetail: `${apiBase}/product/productdetail1`,
  getInfo: `${apiBase}/product/jdproject/getInfoById.json`,
  confirmProject: `${apiV2Base}/order/confirm`,
  listByDepartmentId: `${apiV2Base}/project/listByDepartmentId`,
  detail: `${apiV2Base}/project/detail`,
  // project_order_id 查看用户单个志愿详情
  userProjectDetail: `${apiV2Base}/user/project/detail`
  // 保存用户志愿  uploadFileForProject

};

export const payUrl = {
  aliPay: `${apiBase}/pay/buyproductPre.json`,
  wechatPay: `${apiBase}/pay/getproductwechatcode.json`,
  getPaymentStatus: `${apiBase}/pay/queryproductorder.json`,
  getOrder: `${apiBase}/product/getOrder`,
  placeOrder: `${apiBase}/v2/placeOrder`,
  getOrderDetail: `${apiBase}/v2/getOrderDetail`
};

export const commonUrl = {
  getQRCodeLoginUrl: `${apiBase}/wx/wmws/createQrCode`,
  checkLogin: `${apiBase}/wx/wmws/whetherTheLogin`,
  getUserInfo: `${apiBase}/user/userinfo.json`,
  logout: `${apiBase}/wx/wmws/logout`
};

export const myUrl = {
  getMyWish: `${apiBase}/product/listmywish.json`,
  getMyOrder: `${apiBase}/product/listmyorder.json`,
  sendPhoneValidateCode: `${apiBase}/user/mailvcode.json`,
  changePhone: `${apiBase}/user/change/phone.json`,
  saveUserInfo: `${apiBase}/user/saveuserinfo.json`,
  changeWx: `${apiBase}/wx/wmws/createQrCode`
};

export const productUrl = {
  signContract: `${apiBase}/product/signContract`,
  productDetail: `${apiBase}/product/productdetail`,
  productDetailv2: `${apiV2Base}/product/detail`,
  signedContractCb: `${apiBase}/product/signedContractCb`,
  searchMyOrder: `${apiBase}/v2/searchMyOrder`
};

export const singleProjectDetailUrl = {
  getSingleProjectDetail: `${apiV2Base}/major/detail`,
  majorLike: `${apiV2Base}/major/like`,
  majorUnLike: `${apiV2Base}/major/unlike`
};

export const chooseSchoolPlanUrl = {
  getChooseSchoolPlanUrl: `${apiV2Base}/plan/detail`
};

export const APIPath = {
  changeEmail: `${apiV2Base}/user/changeEmail`,
  listWishByUser: `${apiV2Base}/major/listByUser`,
  contractVerify: `${apiV2Base}/contract/verify`,
  searchMyOrder: `${apiV2Base}/order/searchMyOrder`,
  placeOrder: `${apiV2Base}/order/placeOrder`,
  saveUserInfo: `${apiV2Base}/user/saveUserInfo`,
  listMyOrder: `${apiV2Base}/user/listMyOrder`,
  previewContract: `${apiV2Base}/order/previewContract`,
  updateChooseSchoolPlan: `${apiV2Base}/plan/update`,
  getSchoolPlanDetail: `${apiV2Base}/plan/detail`,
  getStudentDeatil: `${apiV2Base}/user/detail`,
  updateStudent: `${apiV2Base}/user/update`,
  getUploadToken: `${apiV2Base}/qiniu/uploadToken`,
  uploadProjectFiles: `${apiV2Base}/project/uploadFile`,
  majorLikeList: `${apiV2Base}/major/like/list`
};
