import { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import QRCodeLoginModal from './QRCodeLoginModal';
import QRCodeLoginModalContext from '$context/QRCodeLoginModalContext';
import useChangePhone from './useChangePhone';
import { UserInfoContext } from '$context/UserInfoContext';
import { paths } from '$const/paths';
import { useLocation } from 'react-router-dom';

const QRCodeLoginModalProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const { openDialog, mount } = useChangePhone();
  const { userInfo, loaded } = useContext(UserInfoContext);

  const handleOpenQrCodeLoginModal = useCallback((mode?: 'wechat' | 'phone') => {
    if (Object.keys(userInfo).length === 0 || mode === 'wechat') {
      setLoginModalOpen(true);
    } else {
      openDialog();
    }
  }, [openDialog, userInfo]);

  const location = useLocation();

  const shouldAlwaysOpen = useMemo(() => {
    return loaded && Object.keys(userInfo).length === 0 && [paths.product].includes(location.pathname);
  }, [loaded, location, userInfo]);

  return <QRCodeLoginModalContext.Provider
    value={{
      modalOpen: loginModalOpen,
      openQrCodeLoginModal: handleOpenQrCodeLoginModal
    }}
  >
    <QRCodeLoginModal
      loginModalOpen={shouldAlwaysOpen || loginModalOpen}
      setLoginModalOpen={setLoginModalOpen}
      openSetPhone={openDialog}
    />
    {mount}
    {children}
  </QRCodeLoginModalContext.Provider>;
};

export default QRCodeLoginModalProvider;
