export const phonePrefixList = [
  {
    value: '0044',
    desc: '+44 UK (英国)'
  },
  {
    value: '0086',
    desc: '+86 中国大陆'
  },
  {
    value: '001',
    desc: '+1 USA (美国)'
  },
  {
    value: '0061',
    desc: '+61 AUS (澳大利亚)'
  },
  {
    value: '0064',
    desc: '+64 NZ (新西兰)'
  },
  {
    value: '00886',
    desc: '+886 中国台湾'
  },
  {
    value: '00853',
    desc: '+853 中国澳门'
  },
  {
    value: '00852',
    desc: '+852 中国香港'
  },
  {
    value: '0065',
    desc: '+65 新加坡'
  }
];

export const prefixCountryCodeMap = {
  '0044': 'GB',
  '0086': 'CN',
  '001': 'US',
  '0061': 'AUS',
  '0064': 'NZ',
  '00886': 'TW',
  '00853': 'MO',
  '00852': 'HK',
  '0065': 'SG'
};
