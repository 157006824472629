import { UserInfoContext } from '$context/UserInfoContext';
import { useCountDown, useRequest } from 'ahooks';
import { Button, Form, Input, message, Select } from 'antd';
import useForm from 'antd/lib/form/hooks/useForm';
import { useCallback, useContext, useMemo, useState } from 'react';
import { phonePrefixList } from './const';
import { StyledModal } from '../../pages/my/components/setting/style';
import { changePhone, sendPhoneValidateCode } from './service';

const useChangePhone = () => {
  const [open, setOpen] = useState(false);

  const { userInfo } = useContext(UserInfoContext);

  const { runAsync: runSendPhoneValidateCode } = useRequest(sendPhoneValidateCode, { manual: true });
  const { runAsync: runChangePhone } = useRequest(changePhone, { manual: true });

  const { phone } = userInfo;

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const [targetDate, setTargetDate] = useState<number>();

  const [countdown] = useCountDown({
    targetDate
  });

  const options = useMemo(() => {
    return phonePrefixList.map((item: any) => {
      return {
        value: item.value,
        label: item.desc
      };
    });
  }, []);

  const [form] = useForm();

  const handleSendPhoneValiteCode = useCallback(async () => {
    const formValues = form.getFieldsValue();
    const res = await runSendPhoneValidateCode({
      mail: formValues.phone,
      phonePrefix: formValues.phonePrefix
    });
    if (res.status === 0) {
      setTargetDate(new Date().getTime() + 60000);
      void message.success('发送成功');
    }
  }, [form, runSendPhoneValidateCode, setTargetDate]);

  const handleSubmit = useCallback(async () => {
    const formValues = form.getFieldsValue();
    const res = await runChangePhone({
      authcode: formValues.authcode,
      phone: formValues.phone,
      phonePrefix: formValues.phonePrefix
    });
    if (res.status === 0) {
      void message.success('修改成功');
      location.reload();
      setOpen(false);
    } else {
      void message.error('验证码错误');
    }
  }, [form, runChangePhone]);

  const mount = useMemo(() => {
    return <StyledModal
      centered
      open={open}
      closable
      onCancel={() => setOpen(false)}
      title="绑定手机"
      cancelText={null}
      footer={
        <div className='footer'>
          <Button type='primary' onClick={() => { void handleSubmit(); }}>确认修改</Button>
        </div>
      }
      afterClose={() => location.reload()}
    >
      <Form form={form} className="form" initialValues={{ phone, phonePrefix: '0086' }}>
        <Input.Group className='phone-input' compact>
          <Form.Item name='phonePrefix' noStyle>
            <Select options={options} style={{ minWidth: 180 }}/>
          </Form.Item>
          <Form.Item name="phone" noStyle>
            <Input
              placeholder="请输入手机号"
            />
          </Form.Item>
        </Input.Group>
        <Input.Group className='code-input' compact>
          <Form.Item name="authcode" noStyle>
            <Input placeholder="请输入验证码" />
          </Form.Item>
          <Button
            onClick={() => { void handleSendPhoneValiteCode(); }}
            disabled={countdown !== 0}
          >{`获取验证码${countdown !== 0 ? `(${Number(countdown / 1000).toFixed(0)}s)` : ''}`}</Button>
        </Input.Group>
      </Form>
    </StyledModal>;
  }, [countdown, form, handleSendPhoneValiteCode, handleSubmit, open, options, phone]);

  return {
    mount,
    openDialog
  };
};

export default useChangePhone;
