import { lazy, useMemo } from 'react';
import { mobilePaths } from '$const/paths';
import setRootPixel from '@arco-design/mobile-react/tools/flexible';
import { isMobile } from '$utils/isMobile';

if (isMobile) {
  void (async () => {
    await import(/* webpackChunkName: "mobile" */ '@arco-design/mobile-react/esm/style');
    setRootPixel(37.5);
  })();
}

const Login = lazy(
  async () => await import(/* webpackChunkName: "mobile" */ '$mobile-page/login')
);

const Product = lazy(
  async () => await import(/* webpackChunkName: "mobile" */ '$mobile-page/product')
);

const Sign = lazy(
  async () => await import(/* webpackChunkName: "mobile" */ '$mobile-page/sign')
);

const Payment = lazy(
  async () => await import(/* webpackChunkName: "mobile" */ '$mobile-page/payment')
);

const Result = lazy(
  async () => await import(/* webpackChunkName: "mobile" */ '$mobile-page/result')
);

const SingleProjectDetail = lazy(
  async () => await import(/* webpackChunkName: "mobile" */ '$mobile-page/single-project-detail')
);

const ChooseSchoolPlan = lazy(
  async () => await import(/* webpackChunkName: "mobile" */ '$mobile-page/choose-school-plan')
);

const useMobileRouters = () => {
  const routers = useMemo(() => {
    return [
      { path: mobilePaths.login, element: <Login /> },
      { path: mobilePaths.product, element: <Product /> },
      { path: mobilePaths.sign, element: <Sign /> },
      { path: mobilePaths.payment, element: <Payment /> },
      { path: mobilePaths.result, element: <Result /> },
      { path: mobilePaths.singleProjectDetail, element: <SingleProjectDetail /> },
      { path: mobilePaths.chooseSchoolPlan, element: <ChooseSchoolPlan /> }
    ];
  }, []);

  return routers;
};

export default useMobileRouters;
