import isMobileFunc from 'is-mobile';
import eruda from 'eruda';
import { Shake } from './shake';

export const isMobile = isMobileFunc({
  ua: navigator.userAgent
});

const shake = new Shake({ threshold: 15, timeout: 1000 });

shake.addEventListener('shake', ev => {
  eruda.init({
    container: document.body,
    useShadowDom: false,
    tool: ['console', 'network', 'resources']
  });
});
