import request from '$services/request';
import { commonUrl } from '$services/url';
import { GetUserInfoResp } from './interface';

export const getUserInfo = () => {
  return request<GetUserInfoResp>({
    url: commonUrl.getUserInfo
  });
};

export const logout = () => {
  return request({
    url: commonUrl.logout
  });
};
