import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  document.getElementById('root') as HTMLElement
);

root.render(
  <App />
);
