function createEvent <Type extends string, Detail> (
  type: Type,
  detail: Detail
): CustomEvent<Detail> & { type: Type } {
  return new CustomEvent(type, { detail }) as CustomEvent<Detail> & { type: Type };
}

function getMaxAcceleration (event: DeviceMotionEvent): number {
  let max = 0;
  if (event.acceleration) {
    for (const key of ['x', 'y', 'z'] as const) {
      const value = Math.abs(event.acceleration[key] ?? 0);
      if (value > max) max = value;
    }
  }
  return max;
}

export type ShakeEventData = DeviceMotionEvent;
export type ShakeEvent = CustomEvent<ShakeEventData> & { type: 'shake' };
export type ShakeEventListener = (event: ShakeEvent) => void;

export interface ShakeOptions {
  /**
   * Minimum acceleration needed to dispatch an event:
   * meters per second squared (m/s²).
   *
   * https://developer.mozilla.org/en-US/docs/Web/API/DeviceMotionEvent/acceleration
   */
  threshold: number
  /**
   * After a shake event is dispatched, subsequent events will not be dispatched
   * until after a duration greater than or equal to this value (milliseconds).
   */
  timeout: number
}

export class Shake extends EventTarget {
  _approved?: boolean;
  _threshold: ShakeOptions['threshold'];
  _timeout: ShakeOptions['timeout'];
  _timeStamp: number;

  constructor (options?: Partial<ShakeOptions>) {
    super();
    const {
      threshold = 15,
      timeout = 1000
    } = options ?? {};
    this._threshold = threshold;
    this._timeout = timeout;
    this._timeStamp = timeout * -1;
  }

  // @ts-expect-error
  addEventListener (
    type: 'shake',
    listener: ShakeEventListener | null,
    options?: boolean | AddEventListenerOptions
  ): void {
    type Arg1 = Parameters<EventTarget['addEventListener']>[1];
    super.addEventListener(type, listener as Arg1, options);
  }

  dispatchEvent (event: ShakeEvent): boolean {
    return super.dispatchEvent(event);
  }

  // @ts-expect-error
  removeEventListener (
    type: 'shake',
    callback: ShakeEventListener | null,
    options?: EventListenerOptions | boolean
  ): void {
    type Arg1 = Parameters<EventTarget['removeEventListener']>[1];
    super.removeEventListener(type, callback as Arg1, options);
  }

  async approve (): Promise<boolean> {
    if (typeof this._approved === 'undefined') {
      if (!('DeviceMotionEvent' in window)) {
        this._approved = false;
        return true;
      };
      try {
        type PermissionRequestFn = () => Promise<PermissionState>;
        type DME = typeof DeviceMotionEvent & { requestPermission: PermissionRequestFn };
        if (typeof (DeviceMotionEvent as DME).requestPermission === 'function') {
          const permissionState = await (DeviceMotionEvent as DME).requestPermission();
          this._approved = permissionState === 'granted';
        } else this._approved = true;
      } catch {
        this._approved = false;
      }
    }
    return this._approved;
  }

  _handleDeviceMotion = (event: DeviceMotionEvent): void => {
    const diff = event.timeStamp - this._timeStamp;
    if (diff < this._timeout) return;
    const accel = getMaxAcceleration(event);
    if (accel < this._threshold) return;
    this._timeStamp = event.timeStamp;
    this.dispatchEvent(createEvent('shake', event));
  };

  async start (): Promise<boolean> {
    const approved = await this.approve();
    if (!approved) return false;
    window.addEventListener('devicemotion', this._handleDeviceMotion);
    return true;
  }

  stop (): void {
    window.removeEventListener('devicemotion', this._handleDeviceMotion);
  }
}
