import { lazy, useMemo } from 'react';
import { paths } from '$const/paths';

const Home = lazy(
  async () => await import(/* webpackChunkName: "home" */ '$pages/home')
);
const Schools = lazy(
  async () => await import(/* webpackChunkName: "schools" */ '$pages/schools')
);
const Academies = lazy(
  async () => await import(/* webpackChunkName: "academies" */ '$pages/academies')
);
const ProjectDetail = lazy(
  async () => await import(/* webpackChunkName: "project" */ '$pages/project-detail')
);
const Payment = lazy(
  async () => await import(/* webpackChunkName: "project" */ '$pages/payment')
);
const Projects = lazy(
  async () => await import(/* webpackChunkName: "project" */ '$pages/projects')
);
const PDPAPrivate = lazy(
  async () => await import(/* webpackChunkName: "pdpa" */ '$pages/pdpa/Private')
);
const PDPAUser = lazy(
  async () => await import(/* webpackChunkName: "pdpa" */ '$pages/pdpa/User')
);
const My = lazy(
  async () => await import(/* webpackChunkName: "my" */ '$pages/my')
);
const Sign = lazy(
  async () => await import(/* webpackChunkName: "project" */ '$pages/sign/sign')
);
const WishDetail = lazy(
  async () => await import(/* webpackChunkName: "wish" */ '$pages/wish-detail')
);
const Order = lazy(
  async () => await import(/* webpackChunkName: "project" */ '$pages/order')
);
const Wish = lazy(
  async () => await import(/* webpackChunkName: "project" */ '$pages/wish')
);
const Product = lazy(
  async () => await import(/* webpackChunkName: "product" */ '$pages/product')
);
const Rftp = lazy(
  async () => await import(/* webpackChunkName: "Rftp" */ '$pages/rftp-page')
);
const MyInfo = lazy(
  async () => await import(/* webpackChunkName: "Rftp" */ '$pages/my-info')
);
const SingleProjectDetail = lazy(
  async () => await import(/* webpackChunkName: "project" */ '$pages/single-project-detail')
);
const ChooseSchoolPlan = lazy(
  async () => await import(/* webpackChunkName: "mobile" */ '$pages/choose-school-plan')
);

const useRouters = () => {
  const routers = useMemo(() => {
    return [
      // { path: paths.home, element: <Home/> },
      { path: paths.schools, element: <Schools/> },
      { path: paths.academies, element: <Academies/> },
      { path: paths.productDetail, element: <ProjectDetail/> },
      { path: paths.payment, element: <Payment/> },
      { path: paths.sign, element: <Sign/> },
      { path: paths.projects, element: <Projects/> },
      { path: paths.pdpaPrivate, element: <PDPAPrivate/> },
      { path: paths.pdpaUser, element: <PDPAUser/> },
      { path: paths.my, element: <My/> },
      { path: paths.wishDetail, element: <WishDetail/> },
      { path: paths.order, element: <Order/> },
      { path: paths.wish, element: <Wish/> },
      { path: paths.product, element: <Product/> },
      { path: paths.rftp, element: <Rftp/> },
      { path: paths.myInfo, element: <MyInfo/> },
      { path: paths.singleProjectDetail, element: <SingleProjectDetail /> },
      { path: paths.chooseSchoolPlan, element: <ChooseSchoolPlan /> }
    ];
  }, []);

  return routers;
};

export default useRouters;
