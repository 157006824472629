export const colors = {
  primary: '#081B30',
  white: '#FFFFFF',
  black: '#000000',
  blue: '#4A90E2',
  deepBlue: '#435d84',
  deepGray: 'rgb(0 0 0 / 30%)',
  shallowGray: 'rgb(0 0 0 / 20%)',
  shallowBlue: '#4a90e2'
};
