/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import { errorCode } from './const';
import { deleteAllCookies, getFromCookie } from './util';
import { paths } from '$const/paths';

const instance = axios.create({
  baseURL: process.env.APP_API_BASE_URL?.startsWith('http://localhost') ? '/' : process.env.APP_API_BASE_URL,
  timeout: 60 * 2000,
  method: 'POST',
  withCredentials: true
});

instance.interceptors.request.use(
  function (config) {
    const { headers = {} } = config;
    Object.assign(headers, {
      token: getFromCookie('token'),
      from: 'personal-statement-user-end'
    });
    return {
      ...config,
      headers
    };
  },
  async function (error) {
    return await Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    const isUserUnAutorize = response.data?.code === 401;
    if (isUserUnAutorize) {
      deleteAllCookies();
      window.location.href = paths.wish;
    }

    return response.data;
  },
  async function (error) {
    console.error('Request Error', error);
    const response = error.response;
    if (axios.isCancel(error)) {
      return await Promise.reject({
        errorCode: errorCode.CancelRequest,
        errorMsg: 'cancel request'
      });
    }

    return await Promise.reject({
      errorCode: Boolean((response?.status)) || errorCode.NetworkError,
      errorMsg: error.toString()
    });
  }
);
export default instance;
