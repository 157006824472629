import { paths } from '$const/paths';
import { UserInfoContext } from '$context/UserInfoContext';
import { useRequest } from 'ahooks';
import { Button, Checkbox, message, Spin } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { checkIfLogin, getWxQRCodeUrl } from './service';
import { StyledQRCodeLoginModal } from './style';

const QRCodeLoginModal = (props: {
  loginModalOpen: boolean
  setLoginModalOpen: (isOpen: boolean) => void
  openSetPhone: () => void
}) => {
  const { loginModalOpen, setLoginModalOpen, openSetPhone } = props;

  const { loadUserInfo, userInfo } = useContext(UserInfoContext);

  const [qRCodeUrl, setQRCodeUrl] = useState<string>('');

  const { runAsync: fetchWxQrCode } = useRequest(getWxQRCodeUrl, { manual: true });

  const { data: ifLogin, run: startCheckIfLogin, cancel: cancelCheckIfLogin } = useRequest(checkIfLogin, {
    pollingInterval: 2000,
    manual: true
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (typeof ifLogin?.data === 'string') {
      setQRCodeUrl(ifLogin.data);
    }
  }, [ifLogin?.data]);

  useEffect(() => {
    if (Object.keys(userInfo).length > 1) return;
    if ((ifLogin?.phone) != null) {
      cancelCheckIfLogin();
      void message.success('登录成功');
      setLoginModalOpen(false);
      if (ifLogin.phone === '') {
        openSetPhone();
      } else {
        location.reload();
      }
    }
  }, [cancelCheckIfLogin, ifLogin, loadUserInfo, navigate, openSetPhone, setLoginModalOpen, userInfo]);

  const handleCancel = useCallback(() => {
    setLoginModalOpen(false);
  }, [setLoginModalOpen]);

  const loadQrCodeUrl = useCallback(async () => {
    const qrCode = await fetchWxQrCode();
    setQRCodeUrl(qrCode);
  }, [fetchWxQrCode]);

  useEffect(() => {
    if (loginModalOpen) {
      void loadQrCodeUrl();
      startCheckIfLogin();
    } else {
      cancelCheckIfLogin();
    }
  }, [cancelCheckIfLogin, loadQrCodeUrl, loginModalOpen, startCheckIfLogin]);

  return <StyledQRCodeLoginModal title={'微信扫码登录'} open={loginModalOpen} onCancel={handleCancel} footer={null}>
    <div className='modal-content'>
      <div className='line'>手机端微信扫码关注公众号，方可登录成功</div>
      <Spin spinning={!qRCodeUrl}>
        <img className='qr-code' src={qRCodeUrl}/>
      </Spin>
      <div className='line'>
        <Checkbox defaultChecked/>
        <div>已阅读并同意</div>
        <Link target="_blank" to={paths.pdpaPrivate}>
          <Button className='in-line-btn' type='link'>《隐私政策》</Button>
        </Link>
        <Link target="_blank" to={paths.pdpaUser}>
          <Button className='in-line-btn' type='link'>《奇点教育用户协议》</Button>
        </Link>
      </div>
      <div className='line'>说明：若已通过邮箱/手机注册，通过微信登录后进入「账号设置」内绑定即可</div>
    </div>
  </StyledQRCodeLoginModal>;
};

export default QRCodeLoginModal;
