import Layout from './layout/Layout';
import './App.less';
import { useEffect } from 'react';

function App () {
  useEffect(() => {
    if (navigator.appVersion.includes('Win')) {
      document.documentElement.style.setProperty('--font-family', '微软雅黑, sans-serif');
    } else if (navigator.appVersion.includes('Mac')) {
      document.documentElement.style.setProperty('--font-family', 'PingFang SC');
    }
  }, []);
  return <Layout/>;
}

export default App;
