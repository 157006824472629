import { paths } from '$const/paths';
import { UserInfoContext } from '$context/UserInfoContext';
import { useRequest } from 'ahooks';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserEduInfo, UserInfo } from './interface';
import { getUserInfo, logout } from './service';
import { deleteAllCookies } from './util';

const UserInfoContextProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const [userInfo, setUserInfo] = useState<Partial<UserInfo>>({});
  const [userEduInfo, setUserEduInfo] = useState<UserEduInfo[]>([]);
  const [loaded, setLoaded] = useState(false);

  const { data, runAsync: loadUserInfo } = useRequest(getUserInfo, {
    manual: true,
    onSuccess: () => {
      setTimeout(() => {
        setLoaded(true);
      }, 0);
    }
  });

  const { runAsync } = useRequest(logout, { manual: true });

  const navigate = useNavigate();

  const _logout = useCallback(async () => {
    deleteAllCookies();
    await runAsync();
    navigate(paths.wish);
    setUserInfo({});
  }, [navigate, runAsync]);

  useEffect(() => {
    if (data?.user != null) {
      setUserInfo(data.user);
      setUserEduInfo(data.userEduExperiences);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(userInfo).length === 0) {
      void loadUserInfo();
    }
  }, [loadUserInfo, userInfo]);

  return <UserInfoContext.Provider value={{
    userInfo,
    userEduInfo,
    loaded,
    setUserInfo,
    loadUserInfo,
    logout: _logout
  }}>
    {children}
  </UserInfoContext.Provider>;
};

export default UserInfoContextProvider;
