import { ChangePhoneReq, ChangePhoneResp, SendPhoneValidateCodeReq, SendPhoneValidateCodeResp } from '$interafce/my';
import request, { Method } from '$services/request';
import { commonUrl, myUrl } from '$services/url';
import { formatObjectToFormData } from '$services/util';
import { CheckIfLoginResp } from './interface';

const signature = Math.random().toString().slice(-6);

export const getWxQRCodeUrl = () => {
  return request<string>({
    url: commonUrl.getQRCodeLoginUrl,
    method: Method.GET,
    params: { signature }
  });
};

export const checkIfLogin = () => {
  return request<CheckIfLoginResp>({
    url: commonUrl.checkLogin,
    method: Method.GET,
    params: { signature }
  });
};

export const changePhone = (param: ChangePhoneReq) => {
  return request<ChangePhoneResp>({
    url: myUrl.changePhone,
    data: formatObjectToFormData(param)
  });
};

export const sendPhoneValidateCode = (param: SendPhoneValidateCodeReq) => {
  return request<SendPhoneValidateCodeResp>({
    url: myUrl.sendPhoneValidateCode,
    data: formatObjectToFormData(param)
  });
};
