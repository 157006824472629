import { colors } from '$const/color';
import styled from '$utils/styled';

export const StyledHeader = styled.div`
  background-color: ${colors.primary};
  padding: 20px 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.white};
  .icon{
    cursor: pointer;
  }
  .btn{
    color: ${colors.white}
  }
  .right-btns{
    margin-left: auto;
    display: flex;
    .btn{
      margin-right: 64px;
    }
  }
  .login-rigster{
    display: flex;
    align-items: center;
    border: 1px solid ${colors.white};
    border-radius: 6px;
  }
  .my-popover{
    .item{
      display: flex;
      svg{
        width: 28px;
      }
    }
  }
  .ant-popover-inner{
    border-radius: 24px;
  }
  .ant-popover-inner-content{
    padding: 50px 0px;
    width: 312px;
    .item{
      padding: 10px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      :hover{
        cursor: pointer;
        background: #ccc;
      }
      .icon-wrap{
        width: 44px;
        display: flex;
        justify-content: center;
        svg{
          width: 28px;
        }
      }
      .name{
        margin-left: 20px;
      }
    }
  }
`;
