import styled from '$utils/styled';

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  min-height: 100%;
  .page-container{ 
    flex: 1 0 auto;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
  }
`;
