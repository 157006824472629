export const MOBILE_PATH_PREFIX = '/mobile';

export const mobilePaths = {
  login: `${MOBILE_PATH_PREFIX}/login`,
  product: `${MOBILE_PATH_PREFIX}/product`,
  sign: `${MOBILE_PATH_PREFIX}/sign`,
  payment: `${MOBILE_PATH_PREFIX}/payment/`,
  result: `${MOBILE_PATH_PREFIX}/result`,
  singleProjectDetail: `${MOBILE_PATH_PREFIX}/single-project-detail`,
  chooseSchoolPlan: `${MOBILE_PATH_PREFIX}/chooseSchoolPlan`
};

export const paths = {
  home: '/home',
  schools: '/schools',
  academies: '/academies',
  productDetail: '/project-detail',
  payment: '/payment',
  sign: '/sign',
  projects: '/projects',
  pdpaPrivate: '/pdpa-private',
  pdpaUser: '/pdpa-user',
  my: '/my',
  wishDetail: '/wish-detail',
  order: '/order',
  wish: '/wish',
  product: '/product',
  rftp: '/rftp',
  myInfo: 'my-info',
  singleProjectDetail: '/single-project-detail',
  chooseSchoolPlan: '/chooseSchoolPlan'
};

export const pathsNameMap = {
  [paths.wish]: '我的志愿',
  [paths.wishDetail]: '我的志愿详情',
  [paths.order]: '我的订单',
  [paths.my]: '我的',
  [paths.payment]: '收银台',
  [paths.sign]: '签署合同',
  [paths.product]: '产品详情',
  [paths.schools]: '所有学校',
  [paths.academies]: '所有学院',
  [paths.pdpaPrivate]: '隐私政策',
  [paths.projects]: '课程列表',
  [paths.productDetail]: '课程详情',
  [paths.pdpaUser]: '隐私政策',
  [paths.singleProjectDetail]: '专业详情'
};
