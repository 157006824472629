import { UserInfoContextStruct } from '$components/user-info-context-provider/interface';
import { noop } from 'lodash';
import { createContext } from 'react';

export const UserInfoContext = createContext<UserInfoContextStruct>({
  userInfo: {},
  userEduInfo: [],
  loaded: true,
  setUserInfo: noop,
  loadUserInfo: async () => {},
  logout: async () => {}
});
