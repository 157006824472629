import { Suspense, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import useRouters from './useRouters';
import { paths, pathsNameMap } from '$const/paths';

const _Router = () => {
  const routers = useRouters();

  const location = useLocation();

  useEffect(() => {
    document.title = pathsNameMap[location.pathname] || '奇点教育';
  }, [location]);

  return <Suspense fallback={<Spin />}>
    <Routes>
      {routers.map((item) => (
        <Route key={item.path} {...item} element={item.element} />
      ))}
      <Route
        path="*"
        element={<Navigate to={paths.wish} replace />}
      />
    </Routes>
  </Suspense>;
};

export default _Router;
