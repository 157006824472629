import { colors } from '$const/color';
import styled from '$utils/styled';

export const StyledFooter = styled.div`
  background-color: ${colors.primary};
  box-sizing: border-box;
  display: flex;
  position: relative;
  height: 60px;
  .container{
    margin: auto;
    color: ${colors.white};
    font-size: 14px;
  }
`;
