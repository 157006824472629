import { Modal } from 'antd';
import styled from '$utils/styled';

export const StyledSetting = styled.div`
  padding: 60px;
  display: flex;
  flex-direction: column;
  .title{
    color: #435d84;
    font-size: 18px;
  }
  .section{
    display: flex;
    justify-content: space-between;
    width: 400px;
    align-items: center;
    margin-bottom: 20px;
  }
`;

export const StyledChangeWxMpdal = styled(Modal)`
  .ant-modal-body{
    display: flex;
    align-items: center;
    justify-content: center;
    .qr-code{
      width: 122px;
      height: 122px;
    }
  }
`;

export const StyledModal = styled(Modal)`
  .form{
    .ant-input-group{
      display: flex;
    }
  }
  .phone-input{

  }
  .code-input{
    margin-top: 20px;
  }
`;
