import { Suspense, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { MOBILE_PATH_PREFIX, pathsNameMap } from '$const/paths';
import useMobileRouters from './useMobileRouters';

const MobileRouter = () => {
  const routers = useMobileRouters();

  const location = useLocation();

  useEffect(() => {
    document.title = pathsNameMap[location.pathname] || '奇点教育';
  }, [location]);

  return <Suspense fallback={<div />}>
    <Routes>
      {routers.map((item) => (
        <Route
          key={item.path}
          path={item.path.split(MOBILE_PATH_PREFIX)[1]}
          element={item.element}
        />
      ))}
    </Routes>
  </Suspense>;
};

export default MobileRouter;
