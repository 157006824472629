import QRCodeLoginModalProvider from '$components/qr-code-login-modal/QRCodeLoginModalProvider';
import UserInfoContextProvider from '$components/user-info-context-provider';
import { MOBILE_PATH_PREFIX } from '$const/paths';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from './componsnts/footer';
import Header from './componsnts/header';
import Router from './componsnts/router';
import MobileRouter from './componsnts/router/MobileRouter';
import { StyledLayout } from './style';

const Layout = () => {
  return <BrowserRouter>
    <Routes>
      <Route path={`/${MOBILE_PATH_PREFIX}*`} element={<MobileRouter />} />
      <Route
        path='*'
        element={
          <UserInfoContextProvider>
            <QRCodeLoginModalProvider>
              <StyledLayout>
                <Header />
                <div className='page-container'>
                  <Router />
                </div>
                <Footer />
              </StyledLayout>
            </QRCodeLoginModalProvider>
          </UserInfoContextProvider>
        }
      />
    </Routes>
  </BrowserRouter>;
};

export default Layout;
